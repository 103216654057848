import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Card, CardContent, Divider } from "@mui/material";
import { useScreenHomeStore } from "../../store";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { MeasurementsChartsHeader } from './components/measurementsChartsHeader';
import { PieChartMeasurements } from './components/pieChartMeasurements';
import { MeasurementsCubes } from './components/measurementsCubes';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    height: 500
  },
  content: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
      height: "90%"
    }
  }
}));

const MeasurementsChart = observer(() => {
  const classes = useStyles();
  const { isFetching } = useScreenHomeStore();
  return (
    <Card className={classes.root}>
      <MeasurementsChartsHeader />
      <CardContent className={classes.content}>
        <LoadingSpinner isFetching={isFetching} isFullHeight={true} >
          <div>
            <PieChartMeasurements />
            <Divider />
            <MeasurementsCubes />
          </div>
        </LoadingSpinner>
      </CardContent>
    </Card>
  );
});

export default MeasurementsChart;
