import find from "lodash/find";
import { getSegmentIdByPath } from "./getSegmentIdByPath";
import { languageFromServer } from "..";
import { languages } from "common/localization/languages";

export async function updateStoreByTypeOrganization(store, serverRes, pathname) {
  const { allowedSegments, isAllowedHideCouponTypes, isAllowedToSendNotification } = serverRes;
  store.isAllowedToSendNotification = isAllowedToSendNotification;
  store.isAllowedHideCouponTypes = isAllowedHideCouponTypes;
  store.setAllowedSegments(allowedSegments);

  const segmentIdByPath = getSegmentIdByPath(pathname);
  const currentSegmentInArray = getCurrentSegment(store.currentSegmentId, allowedSegments, segmentIdByPath);
  const currentSegmentToSet = currentSegmentInArray || store.currentSegment;
  store.setCurrentSegmentId(currentSegmentToSet.segmentId);
  store.setCurrentSegment(currentSegmentToSet);
  store.setDashboardFeatures(currentSegmentToSet.dashboardFeatures);
  store.setLanguageCode(currentSegmentToSet.languageCode);
  languageFromServer.languageCode = currentSegmentToSet.languageCode;
  const direction = [languages.SPANISH, languages.ENGLISH].includes(languageFromServer.languageCode) ? "ltr" : "rtl";
  document.body.setAttribute("dir", direction);
}

const getCurrentSegment = (segmentIdInStorage, allowedSegments, segmentIdByPath) => {
  const segmentIdToFind = segmentIdByPath || segmentIdInStorage;
  if (!segmentIdToFind) {
    return null;
  }
  if (!isMultiSegmentId(segmentIdToFind)) {
    return find(allowedSegments, (item) => {
      if (!isMultiSegmentId(item.segmentId)) {
        return Number(item.segmentId) === Number(segmentIdToFind);
      }
      return item.segmentId && Number(item.segmentId.mainSegmentId) === Number(segmentIdToFind)
    });
  }
  return find(allowedSegments, (item) => {
    return item.segmentId && Number(item.segmentId.mainSegmentId) === Number(segmentIdToFind.mainSegmentId);
  });
};

function isMultiSegmentId(segmentId) {
  return typeof segmentId === "object";
}