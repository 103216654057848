import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";
import { Button, Menu, MenuItem, ListItemText } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useScreenLoginStore } from "../../store";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  header: {
    borderRadius: 4,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.main,
    borderWidth: 1.5,
    borderStyle: "solid"
  },
  changeSegmentButton: {
    color: theme.palette.primary.main,
    width: "100%"

  },
}));

export const ChooseSegmentDropDown = observer((props) => {
  const store = useScreenLoginStore();
  const { selectedSegment: selectedSegmentStore, allowedSegments } = store;
  const currentSegmentName = selectedSegmentStore ? selectedSegmentStore.segmentName : t("login.segmentCard.chooseSegment.dropDownLabel"); 
  const [openChangeSegment, setOpenChangeSegment] = useState(false);
  const [currentSegment, setCurrentSegment] = useState(currentSegmentName);
  const options = allowedSegments ? allowedSegments.map((item) => item.segmentName) : null;
  const classes = useStyles();
  const changeSegmentRef = useRef(null);

  if (!options || options.length <= 1) {
    return null;
  }
  const optionsComponents = options.map((option) => (
    <MenuItem
      className={classes.menuItem}
      key={option}
      onClick={() => handleChangeSegmentSelect(option)}
    >
      <ListItemText primary={option} />
    </MenuItem>
  ));

  const handleChangeSegmentOpen = () => {
    setOpenChangeSegment(true);
  };

  const handleChangeSegmentClose = () => {
    setOpenChangeSegment(false);
  };

  const handleChangeSegmentSelect = (value) => {
    setCurrentSegment(value);
    const segment = find(allowedSegments, ["segmentName", value]);
    store.setSelectedSegment(segment);
    setOpenChangeSegment(false);
  };

  return (
    <div>
      <div className={classes.header}>
        <Button
          className={classes.changeSegmentButton}
          onClick={handleChangeSegmentOpen}
          ref={changeSegmentRef}
        >
          {currentSegment}
          <ArrowDropDownIcon />
        </Button>
      </div>
      <Menu
        anchorEl={changeSegmentRef.current}
        className={classes.menu}
        onClose={handleChangeSegmentClose}
        open={openChangeSegment}
      >
        {optionsComponents}
      </Menu>
    </div>
  );
});
