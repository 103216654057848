import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import useRouter from "utils/useRouter";
import { CardContent, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ErrorText from "./errorText";
import { ChooseSegmentDropDown } from "./chooseSegmentDropDown.js";
import { CommonButton } from "common/ui/commonButton";
import { useScreenLoginStore } from "../store";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  button: {
    width: "100%",
  }
}));

export const ChooseSegmentCard = observer(() => {
  const classes = useStyles();
  const store = useScreenLoginStore();
  const router = useRouter();
  const onClick = () => {
    store.onSubmitDashboardSegment(router)
  }
  return (
    <CardContent className={classes.content}>
      <LockIcon className={classes.icon} />
      <Typography gutterBottom variant="h3">
        {t('login.segmentCard.chooseSegment.label')}
      </Typography>
      <ChooseSegmentDropDown />
      <br />
      <CommonButton size="large" className={classes.button} onClick={onClick}>{t("login.segmentCard.chooseSegment.button")}</CommonButton>
      <ErrorText />
    </CardContent>
  );
});
